<template>
  <div fluid class="warehouse-detail">
    <Breadcrumb :items="breadCrumbs" />
    <v-container>
      <v-row no-gutters justify="end">
        <router-link
          class="text-caption font-weight-bold text-decoration-none primary--text text--lighten-1"
          :to="{
            name: 'WarehouseProvince',
            params: { province: province }
          }"
        >
          {{ $t("storeLocator.backToList") }}
          <v-icon x-small color="primary lighten-1">$chevronRight</v-icon>
        </router-link>
      </v-row>
      <v-row v-if="warehouse">
        <v-col cols="12" md="6">
          <v-card elevation="0" class="h-100" :key="warehouse.warehouseId">
            <v-card-title>
              <h1>
                <ebsn-meta :target="warehouse" path="warehouse_info.NAME">
                </ebsn-meta>
              </h1>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <div class="mb-4">
                    <div class="font-weight-bold">Indirizzo</div>
                    <div>
                      {{ formattedAddress(warehouse) }}
                    </div>
                  </div>
                  <div v-if="email">
                    <div class="font-weight-bold">Email:</div>
                    <a
                      class="text-caption font-weight-bold primary--text text--lighten-1 text-decoration-none"
                      :href="'mailto:' + email"
                      >{{ email }}</a
                    >
                  </div>
                  <div>
                    <a
                      v-if="warehouse.address"
                      title="Indicazioni stradali"
                      class="text-caption font-weight-bold primary--text text--lighten-1 text-decoration-none"
                      target="_blank"
                      :href="
                        `https://www.google.com/maps/dir/?dir_action=navigate&api=1&destination=${warehouse.address.latitude},${warehouse.address.longitude}`
                      "
                    >
                      {{ $t("storeLocator.googleAddress") }}
                      <v-icon x-small color="primary lighten-1"
                        >$chevronRight</v-icon
                      ></a
                    >
                  </div>
                  <v-spacer />
                  <div class="my-3">
                    <div class="font-weight-bold">
                      {{ $t("storeLocator.divisions") }}
                    </div>
                    <img
                      class="ml-1 align-center"
                      v-for="(repartoIcon, idx) in filteredAddressClasses"
                      :key="idx"
                      :src="repartoIcon.iconSource"
                      :alt="repartoIcon.name"
                      height="20"
                      width="20"
                    />
                  </div>
                  <div>
                    <div class="font-weight-bold">
                      {{ $t("storeLocator.services") }}
                    </div>
                    <img
                      v-if="hasDrive"
                      alt="Servizio Drive"
                      height="25px"
                      class="mr-2"
                      src="/services-icons/clicca-e-ritira.svg"
                    />
                    <img
                      v-if="hasLocker"
                      alt="Servizio Locker"
                      height="25px"
                      class="mr-2"
                      src="/services-icons/locker.svg"
                    />
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="font-weight-bold">
                    {{ $t("storeLocator.openingTimes") }}
                  </div>
                  <div
                    v-if="openingTimes"
                    v-html="openingTimes"
                    class="opening-times-row small-text"
                  ></div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <GmapMap
            v-if="warehouse.warehouseId"
            ref="mapRef"
            :center="{
              lat: warehouse.address.latitude,
              lng: warehouse.address.longitude
            }"
            :zoom="15"
            :style="{ width: mapWidth, height: mapHeight }"
            :options="{
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              disableDefaultUI: false
            }"
          >
          </GmapMap>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <product-list-slider
        v-if="warehouse"
        :layout="22"
        :warehouseId="warehouse.warehouseId"
        :promo="true"
        :shuffle="true"
        :limit="12"
      />
    </v-container>
  </div>
</template>

<script>
import AddressService from "~/service/addressService";
import Breadcrumb from "@/components/navigation/Breadcrumb";
import ProductListSlider from "@/components/product/ProductListSlider";

import { gmapApi } from "vue2-google-maps";

export default {
  name: "WarehouseDetail",
  components: { Breadcrumb, ProductListSlider },
  props: {
    province: { type: String }
  },
  data() {
    return {
      warehouseList: [],
      warehouse: {}
    };
  },
  computed: {
    google: gmapApi,
    mapWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "351px";
        case "sm":
          return "573px";
        case "md":
          return "563px";
        case "lg":
          return "754px";
        case "xl":
          return "1061px";
      }
      return "850px";
    },
    mapHeight() {
      return "320px";
    },
    breadCrumbs() {
      let breadCrumbs = [
        { to: { name: "Home" }, text: "Homepage", exact: true },
        {
          to: { name: "WarehouseLocator" },
          text: "Punti Vendita",
          exact: true
        }
      ];
      if (this.warehouse) {
        breadCrumbs.push({
          to: {
            name: "WarehouseDetail",
            params: {
              warehouseId: this.warehouse.warehouseId
            }
          },
          text: this.warehouse.name,
          exact: true
        });
      }
      return breadCrumbs;
    },
    email() {
      return this.$ebsn.meta(this.warehouse, "warehouse_info.EMAIL", null);
    },
    openingTimes() {
      return this.$ebsn.meta(
        this.warehouse,
        "warehouse_info.OPENING_TIMES",
        null
      );
    },
    hasDrive() {
      return this.$ebsn.meta(this.warehouse, "warehouse_service.DRIVE", null);
    },
    hasLocker() {
      return this.$ebsn.meta(this.warehouse, "warehouse_service.LOCKER", null);
    },
    filteredAddressClasses() {
      if (this.warehouse.address && this.warehouse.address.addressClass) {
        return this.warehouse.address.addressClass.filter(item => {
          return !(
            item.iconSource === null || item.iconSource.trim().length === 0
          );
        });
      } else {
        return [];
      }
    }
  },
  methods: {
    formattedAddress(warehouse) {
      if (warehouse && warehouse.address) {
        return `${warehouse.address.address1}, ${warehouse.address.city} - (${warehouse.address.province}) ${warehouse.address.postalcode}`;
      }
    },
    async findWarehouse() {
      if (this.warehouseList.length == 0) {
        let data = await AddressService.findWarehouse({
          region_id: this.selectedRegionId
        });
        this.warehouseList = data.warehouses;
      }
      this.warehouse = this.warehouseList.find(
        element => element.warehouseId == this.$route.params.warehouseId
      );
    }
  },
  mounted() {
    this.findWarehouse();
  },
  watch: {
    $route() {
      this.findWarehouse();
    }
  }
};
</script>
